import * as THREE from 'three';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js';
import fragment from './shaders/fragment.glsl'
import vertex from './shaders/vertex.glsl'
import * as dat from 'dat.gui'
import gsap from 'gsap'
// init

import {DotScreenShader} from './customShader'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

export default class Sketch{
    constructor(options){
        this.container = options.domElement;
        this.rect = this.container.getBoundingClientRect();
        console.log(this.rect);
        this.width=this.container.offsetWidth;
        this.height=this.container.offsetHeight;
        this.camera = new THREE.PerspectiveCamera( 70, window.innerWidth / window.innerHeight, 0.01, 10 );
        this.camera.position.set(0,0,1.3);

        
        this.scene = new THREE.Scene();
        // this.scene.add(this.background)
        
        this.renderer = new THREE.WebGLRenderer( { 
            antialias: true,
            alpha: true
         } );
         this.renderer.setPixelRatio(window.devicePixelRatio);
         // this.renderer.setPixelRatio(2);
         this.container.appendChild(this.renderer.domElement);
        //  this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.offset = new THREE.Vector2(0,0);
        this.targetX = 0;
        this.targetY = 0;
        this.time = 0;
        this.initPost();
        this.resize();
        this.addObjects();
        this.mouseMove();
        this.animation();
        this.render();
        // this.setupSettings();
        this.setupResize();




    }

    animation(){
        window.addEventListener('mousemove', (e) => {
            let pos = e;
            var title = document.getElementById('title');
            var subTitle = document.getElementById('subTitle');
            let titleTl = gsap.timeline();
            let mouseX = pos.screenX;
            let mouseY = pos.screenY;
            titleTl.add('start')
            .to(title,{
                x: -((this.rect.width/2)- mouseX)/10,
                y: -((this.rect.height/2)- mouseY)/10,
            },'start')
            .to(subTitle,{
                x: -((this.rect.width/2)- mouseX)/15,
                y: -((this.rect.height/2)- mouseY)/15,
            },'start')
        })
        console.log('hey');
    }

    initPost(){

        this.composer = new EffectComposer( this.renderer );
        this.composer.addPass( new RenderPass( this.scene, this.camera ) );

        const effect1 = new ShaderPass( DotScreenShader );
        effect1.uniforms[ 'scale' ].value = 4;
        this.composer.addPass( effect1 );

    }
    setupSettings(){
        this.settings = {
            progress: 0
        }
        this.gui = new dat.GUI();
        this.gui.add(this.settings,"progress",0,1,0.001);

    }

    resize(){
        this.width = this.container.offsetWidth;
        this.height = this.container.offsetHeight;
        this.renderer.setSize( this.width, this.height );
        // this.composer.setSize(this.width,this.height);
        this.camera.aspect = this.width/this.height;
        this.camera.updateProjectionMatrix();

    }

    mouseMove(){
        window.addEventListener('mousemove', (e) => {
            this.targetX = e.clientX;
            this.targetY = e.clientY;
        })
        
    }

    setupResize(){
        window.addEventListener('resize',this.resize.bind(this));
    }

    addObjects(){
        this.geometry = new THREE.SphereBufferGeometry( 1.5,32,32);
        this.material = new THREE.ShaderMaterial({
            side: THREE.DoubleSide,
            // wireframe: true,
            uniforms: {
                time: { value: 1.0 },
                uProgress: { value: 0 },
                uTextureSize: {value: new THREE.Vector2(100,100)},
                uResolution: { value: new THREE.Vector2(this.width,this.height) },
                uMouse:{value : new THREE.Vector2(0.,0.)}
            },
            vertexShader: vertex,
            fragmentShader: fragment,
        })
        this.mesh = new THREE.Mesh( this.geometry, this.material );
        this.scene.add( this.mesh );

    }

    render(){
        this.time += 0.01;
        this.material.uniforms.time.value = this.time;
        if (window.innerWidth < 768) {
            this.material.uniforms.uMouse.value.set(1.,1. );

        }else {
            this.material.uniforms.uMouse.value.set((this.targetX- this.offset.x) * 0.0005 , -(this.targetY- this.offset.y) * 0.0005 );
        }
        // this.renderer.render( this.scene, this.camera );
        this.composer.render( this.scene, this.camera );
        requestAnimationFrame(this.render.bind(this))
    }

}

new Sketch({
    domElement: document.getElementById('container')
})



